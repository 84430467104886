export default function Spinner() {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
    >
      <path
        d="M24 13L23.9581 12.0413L23.8329 11.0899L23.6252 10.153L23.3366 9.23778L22.9694 8.3512L22.5263 7.5L22.0107 6.69066L21.4265 5.92934L20.7782 5.22183L20.0707 4.57351L19.3093 3.98933L18.5 3.47372L17.6488 3.03061L16.7622 2.66338L15.847 2.37482L14.9101 2.16711L13.9587 2.04186L13 2L12.0413 2.04186L11.0899 2.16711L10.153 2.37482L9.23778 2.66338L8.3512 3.03061L7.5 3.47372L6.69066 3.98933L5.92934 4.57351L5.22183 5.22183L4.57351 5.92934L3.98933 6.69066L3.47372 7.5L3.03061 8.3512L2.66338 9.23778L2.37482 10.153L2.16711 11.0899L2.04186 12.0413L2 13L2.04186 13.9587L2.16711 14.9101L2.37482 15.847L2.66338 16.7622L3.03061 17.6488"
        stroke="#DF4091"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}
